import React, { Component } from 'react';
import { makeStyles } from '@mui/styles';
import {Paper, Tabs, Tab, Typography, Box} from '@mui/material';
import ContactLeadsReportRegular from './ContactLeadsReportRegular';
import ContactLeadsReportHourly from './ContactLeadsReportHourly';
import TeleFuReport from './TeleFuReport';
import EventReminderReport from './EventReminderReport';
import Global from '../../customlib/Global';
import LeadsReportSummary from './LeadsReportSummary';
import CheckPointReport from './CheckPointReport';
import HistoryPerubahanLabel from './HistoryPerubahanLabel';
import InboundByreferral from './InboundByReferral';
import LeadsMonitoring from './LeadsMonitoring';
import TeleChatByCustomer from './TeleChatByCustomer';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

class ContactLeadsReport extends Component {

    state = { 
        value : 0,
    }

    useStyles = makeStyles({
        root: {
          flexGrow: 1,
        },
      });

    // classes = useStyles();
    // const [value, setValue] = React.useState(0);

    componentDidMount() {
        this.setState({value: 0});
    }
    

    handleChange = (event, newValue) => {
        // setValue(newValue);
        this.setState({value:newValue});
    };

    render() { 
        return ( 
            <div className='container-fluid'>
            <Paper className={this.useStyles.root}>
                {/* <div className="container-fluid text-right bg-primary">
                    <Link to="/">
                        <div className="btn btn-primary">Close</div>
                    </Link>
                </div> */}
                
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    centered
                >
                    <Tab key="tab0" label="By Sales" />
                    {/* <Tab key="tab1" label="Leads Monitoring" /> */}
                    <Tab key="tab1" label="By Hours" />
                    <Tab key="tab2" label="Tele FU Report" />
                    {
                      Global.get().admin === true || Global.isTeleAdmin() === true ? (<Tab key="tab3" label="Tele Di Chat Oleh Customer" />) : (<></>)
                    }
                    
                    <Tab key="tab4" label="ER Report" />
                    <Tab key="tab5" label="Leads Report Summary" />
                    {
                      Global.get().admin === true ? (<Tab key="tab6" label="Check Point" />) : (<></>)
                    }
                    {
                      Global.get().admin === true ? (<Tab key="tab7" label="Riwayat Label" />) : (<></>)
                    }
                    {
                      Global.get().admin === true ? (<Tab key="tab8" label="By Referral" />) : (<></>)
                    }

                    {
                      Global.get().admin === true ? (<Tab key="tab9" label="Leads Monitoring" />) : (<></>)
                    }
                    
                    
                </Tabs>
            </Paper>
            <TabPanel value={this.state.value} index={0}>
                <ContactLeadsReportRegular />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
                <ContactLeadsReportHourly />
            </TabPanel>
            <TabPanel value={this.state.value} index={2}>
                <TeleFuReport />
            </TabPanel>
            <TabPanel value={this.state.value} index={3}>
                <TeleChatByCustomer />
            </TabPanel>
            <TabPanel value={this.state.value} index={4}>
                <EventReminderReport />
            </TabPanel>
            <TabPanel value={this.state.value} index={5}>
                <LeadsReportSummary />
            </TabPanel>
            <TabPanel value={this.state.value} index={6}>
              <CheckPointReport />
            </TabPanel>
            <TabPanel value={this.state.value} index={7}>
              <HistoryPerubahanLabel />
            </TabPanel>
            <TabPanel value={this.state.value} index={8}>
              <InboundByreferral />
            </TabPanel>
            <TabPanel value={this.state.value} index={9}>
                <LeadsMonitoring />
            </TabPanel>
          </div>
         );
    }
}
 
export default ContactLeadsReport;